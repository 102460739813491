import { API } from "../../constans/Url";
import { apiClient } from "../../helper";

const get_ListApplication = (successCallback, errorCallback) => {
  apiClient(true).get(API.INTEGRATIONS.LIST_APPLICATION).then(result => {
    if (result.status == 200) {
      successCallback(result.data)
    } else {
      errorCallback(result.data)
    }
  }).catch(err => {
    successCallback(err)
  })
}

const post_CheckIntegrations = (params, successCallback, errorCallback) => {
  apiClient(true).post(API.INTEGRATIONS.CHECK, params).then(result => {
    if (result.status == 200) {
      successCallback(result.data)
    } else {
      errorCallback(result.data)
    }
  }).catch(err => {
    successCallback(err)
  })
}

const post_InitIntegrations = (params, successCallback, errorCallback) => {
  apiClient(true).post(API.INTEGRATIONS.INIT, params).then(result => {
    if (result.status == 200) {
      successCallback(result.data)
    } else {
      errorCallback(result.data)
    }
  }).catch(err => {
    successCallback(err)
  })
}

const get_DetailIntegrations = (id, successCallback, errorCallback) => {
  apiClient(true).get(API.INTEGRATIONS.GET.replace(':slug', id)).then(result => {
    if (result.status == 200) {
      successCallback(result.data)
    } else {
      errorCallback(result.data)
    }
  }).catch(err => {
    successCallback(err)
  })
}

const post_RemoveIntegrations = (params, successCallback, errorCallback) => {
  apiClient(true).post(API.INTEGRATIONS.REMOVE, params).then(result => {
    if (result.status == 200) {
      successCallback(result.data)
    } else {
      errorCallback(result.data)
    }
  }).catch(err => {
    successCallback(err)
  })
}

const post_SyncTable = (params = {
  id_company: '',
  table: ''
}, successCallback, errorCallback) => {
  apiClient(true).post(API.INTEGRATIONS.SYNC_TABLE, params).then(result => {
    if (result.status == 200) {
      successCallback(result.data)
    } else {
      errorCallback(result.data)
    }
  }).catch(err => {
    successCallback(err)
  })
}


export {
  get_ListApplication,
  post_CheckIntegrations,
  post_InitIntegrations,
  get_DetailIntegrations,
  post_RemoveIntegrations,
  post_SyncTable
}